<template>
  <div class="d-flex justify-content-end">
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "RewardingSchemePagination",
  computed: {
    currentPage: {
      get() {
        return this.$store.state.rewardingSchemeTable.currentPage;
      },
      set(value) {
        this.$store.commit("rewardingSchemeTable/SET_CURRENT_PAGE", value);
        this.$store.dispatch("rewardingSchemeTable/getRewardingSchemes");
      },
    },
    totalRows() {
      return this.$store.state.rewardingSchemeTable.totalRows;
    },
    perPage() {
      return this.$store.state.rewardingSchemeTable.perPage;
    },
  },
};
</script>

<style scoped></style>
