<template>
  <div class="p-4">
    <rewarding-scheme-search />
    <rewarding-scheme-table />
    <rewarding-scheme-pagination />
    <rewarding-scheme />
  </div>
</template>

<script>
import RewardingSchemeSearch from "../components/rewarding-scheme/RewardingSchemeSearch";
import RewardingSchemeTable from "../components/rewarding-scheme/RewardingSchemeTable";
import RewardingScheme from "../components/rewarding-scheme/modals/RewardingScheme";
import RewardingSchemePagination from "../components/rewarding-scheme/RewardingSchemePagination";
export default {
  name: "MRewardingScheme",
  components: {
    RewardingSchemePagination,
    RewardingScheme,
    RewardingSchemeTable,
    RewardingSchemeSearch,
  },
  async created() {
    await this.$store.dispatch("rewardingSchemeTable/getRewardingSchemesMeta");
    this.$store.commit("productsTable/SET_PER_PAGE", 5);
    await this.$store.dispatch("productsTable/getProducts");
  },
};
</script>

<style scoped></style>
