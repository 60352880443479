<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h6>Create and edit rewards that users can claim.</h6>
    <div class="d-flex align-items-center justify-content-end col-6 px-0">
      <v-date-picker
        v-model="dateRange"
        color="red"
        is-range
        class="mr-2 col-4 px-0"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <b-input-group>
            <b-input-group-append>
              <div class="m-input__icon left">
                <i class="far fa-calendar-day"></i>
              </div>
            </b-input-group-append>
            <b-form-input
              id="dateRange"
              type="text"
              size="sm"
              class="border-left-0 border-right-0 pl-0 py-2"
              readonly
              :value="inputValue.start ? getDate(inputValue) : 'Filter by date'"
              @focus="togglePopover"
              @blur="togglePopover"
            ></b-form-input>
            <b-input-group-prepend>
              <div class="m-input__icon right show-cursor" @click="clearDate">
                <i class="fal fa-times"></i>
              </div>
            </b-input-group-prepend>
          </b-input-group>
        </template>
      </v-date-picker>
      <b-input-group class="col-4 px-0">
        <b-input-group-append>
          <div class="m-input__icon left">
            <i class="far fa-search"></i>
          </div>
        </b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search rewards"
          class="border-left-0 pl-0 py-2 mr-2"
          debounce="500"
        ></b-form-input>
      </b-input-group>
      <b-button variant="primary" class="mr-2" @click="createNew"
        ><i class="far fa-plus mr-2"></i>Create new</b-button
      >
      <b-button
        variant="primary"
        :disabled="downloading"
        v-b-tooltip.hover="'Export to csv'"
        @click="exportCsv"
        ><i class="fas fa-download"></i
      ></b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RewardingSchemeSearch",
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    searchQuery: {
      get() {
        return this.$store.state.rewardingSchemeTable.searchQuery;
      },
      set(value) {
        this.$store.commit("rewardingSchemeTable/SET_CURRENT_PAGE", 1);
        this.$store.commit("rewardingSchemeTable/SET_SEARCH_QUERY", value);
        this.$store.dispatch("rewardingSchemeTable/getRewardingSchemes");
      },
    },
    dateRange: {
      get() {
        return this.$store.state.rewardingSchemeTable.dateRange;
      },
      set(value) {
        this.$store.commit("rewardingSchemeTable/SET_DATE_RANGE", value);
        this.$store.dispatch("rewardingSchemeTable/getRewardingSchemes");
      },
    },
  },
  methods: {
    getDate(dateRange) {
      return `${this.$options.filters.date(
        dateRange.start
      )} - ${this.$options.filters.date(dateRange.end)}`;
    },
    clearDate() {
      this.dateRange = {
        start: null,
        end: null,
      };
    },
    async exportCsv() {
      try {
        this.downloading = true;
        const res = await this.$axios.get("/rewards/export", {
          responseType: "blob",
        });
        this.downloadFile(res.data, "rewards");
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
    createNew() {
      this.$bvModal.show("rewarding-scheme");
    },
  },
};
</script>

<style scoped></style>
