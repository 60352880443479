<template>
  <b-modal
    id="rewarding-scheme"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetState"
  >
    <template #modal-header>
      <h4 class="m-modal__title">{{ edit ? "Edit" : "Create" }} reward</h4>
    </template>
    <div class="d-flex align-items-center info-box info mb-4">
      <i class="fas fa-info-circle icon mr-3"></i>
      <p class="mb-0">
        Each user has a certain number of loyalty points, which can be spent on
        selected products and discounts, depending on the reward scheme defined
        by the input fields below.<br />
        <b>Any changes will be effecting immediately.</b>
      </p>
    </div>
    <validation-observer ref="rewardSchemaForm" class="d-flex flex-wrap w-100">
      <validation-provider
        tag="div"
        name="name"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Name</span>
        <b-form-input
          v-model="name"
          type="text"
          size="sm"
          :disabled="requesting"
        ></b-form-input>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="div"
        name="store"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pr-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Store</span>
        <b-form-select v-model="storeId" size="sm">
          <b-form-select-option
            v-for="store in stores"
            :key="store.id"
            :value="store.id"
            >{{ store.name }}</b-form-select-option
          >
        </b-form-select>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <div class="d-flex">
        <span class="d-flex align-items-center"
          >Once the user collects
          <validation-provider
            tag="span"
            name="token amount"
            rules="required|min_value:1"
            class="d-flex flex-column pr-0 mx-2"
            v-slot="{ errors }"
          >
            <b-form-input
              v-model="tokenAmount"
              type="number"
              size="sm"
              :disabled="requesting"
              no-wheel
              number
              required
              class="col-12 d-inline py-2"
            ></b-form-input>

            <span class="text-danger error">{{ errors[0] }}</span>
          </validation-provider>
          loyalty points, they can choose from rewards such as:
          <validation-provider
            tag="span"
            name="reward type"
            rules="required"
            class="d-flex flex-column pr-0 mx-2 align-items-center"
            v-slot="{ errors }"
          >
            <b-form-select v-model="type" size="sm" class="col-12 py-2">
              <b-form-select-option
                v-for="(type, index) in types"
                :key="index"
                :value="type"
                >{{ type }}</b-form-select-option
              >
            </b-form-select>
            <span class="text-danger error">{{ errors[0] }}</span>
          </validation-provider>
        </span>
      </div>
      <validation-provider
        v-if="type === 'discount'"
        tag="div"
        name="Discount amount"
        rules="required|min_value:1"
        class="d-flex mb-3 col-12 pr-0 align-items-center mt-3 pl-0"
        v-slot="{ errors }"
      >
        <span>Discount amount: </span>
        <b-input-group class="col-2 px-0 ml-2">
          <b-form-input
            id="search"
            v-model="discountValue"
            type="text"
            size="sm"
            class="border-right-0 pl-1 py-2"
          ></b-form-input>
          <b-input-group-prepend>
            <div class="m-input__icon right">
              <i class="fas fa-percent"></i>
            </div>
          </b-input-group-prepend>
        </b-input-group>
        <span class="text-danger error ml-2">{{ errors[0] }}</span>
      </validation-provider>
      <reward-products v-else-if="type === 'freeProducts'"></reward-products>

      <validation-provider
        tag="div"
        name="repeating"
        class="d-flex flex-column mb-3 col-12 px-0 mt-4"
        v-slot="{ errors }"
      >
        <b-form-checkbox v-model="isTimeLimited">Time limited</b-form-checkbox>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        v-if="isTimeLimited"
        tag="div"
        name="valid"
        rules="date-validation"
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Valid (from - to)</span>
        <m-date-select v-model="valid" />
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="div"
        name="repeating"
        class="d-flex flex-column mb-3 col-12 px-0 mt-3"
        v-slot="{ errors }"
      >
        <b-form-checkbox v-model="isRepeating"
          >Reward is repeating</b-form-checkbox
        >
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <div v-if="isRepeating" class="d-flex flex-column col-12 px-0 mb-3">
        <validation-provider
          tag="div"
          name="repetition interval"
          rules="required|min_value:1"
          class="d-flex flex-column mb-3 col-6 pl-0"
          v-slot="{ errors }"
        >
          <span class="m-input__label is-required"
            >Every N week/s (repetition interval)</span
          >
          <b-form-input
            v-model="perWeeks"
            type="number"
            size="sm"
            no-wheel
            number
            :disabled="requesting"
          ></b-form-input>
          <span class="text-danger error">{{ errors[0] }}</span>
        </validation-provider>
        <div class="d-flex align-items-center">
          <span class="mr-2 font-weight-bold">On</span>
          <b-form-checkbox-group v-model="repeatingDays">
            <b-form-checkbox
              v-for="day in week"
              :key="day"
              :value="day"
              class="week-button"
              >{{ day }}</b-form-checkbox
            >
          </b-form-checkbox-group>
        </div>
      </div>
    </validation-observer>
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center w-100">
        <b-form-checkbox v-if="edit" v-model="active"
          >Reward is <b>{{ active ? "enabled" : "disabled" }}</b>
        </b-form-checkbox>
        <div class="d-flex align-items-center ml-auto">
          <b-button
            variant="secondary"
            class="mr-2"
            :disabled="requesting"
            @click="close"
            >Close</b-button
          >
          <b-button variant="primary" :disabled="requesting" @click="validate"
            >Save <span v-if="edit">changes</span></b-button
          >
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RewardProducts from "./rewarding-scheme-types/RewardProducts";
import MDateSelect from "../../gadgets/MDateSelect";
export default {
  name: "RewardingScheme",
  components: { MDateSelect, RewardProducts },
  data() {
    return {
      week: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
    };
  },
  computed: {
    edit() {
      return this.$store.state.rewardingScheme.edit;
    },
    requesting() {
      return this.$store.state.rewardingSchemeTable.requesting;
    },
    stores() {
      return this.$store.state.rewardingSchemeTable.stores;
    },
    types() {
      return this.$store.state.rewardingSchemeTable.rewardingSchemeTypes;
    },
    rewardingSchemeTypeComponent() {
      let component;
      switch (this.type) {
        case "order":
          component = "purchase-rewardingScheme";
          break;
        case "survey":
          component = "survey-rewardingScheme";
          break;
        case "url":
          component = "url-rewardingScheme";
          break;
        case "products":
          component = "product-rewardingScheme";
          break;
      }
      return component;
    },
    active: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.isActive;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "isActive",
          value,
        });
      },
    },
    name: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.name;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "name",
          value,
        });
      },
    },
    discountValue: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.discountValue;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "discountValue",
          value,
        });
      },
    },
    tokenAmount: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.tokenAmount;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "tokenAmount",
          value,
        });
      },
    },
    storeId: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.storeId;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "storeId",
          value,
        });
      },
    },
    valid: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.valid;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "valid",
          value,
        });
      },
    },
    type: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.type;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "type",
          value,
        });
        this.$store.commit(
          "rewardingScheme/UPDATE_REWARDING_SCHEME_PRODUCTS",
          []
        );
      },
    },
    isRepeating: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.repetition
          .isRepeating;
      },
      set(value) {
        this.$store.commit(
          "rewardingScheme/UPDATE_REWARDING_SCHEME_REPETITION",
          {
            field: "isRepeating",
            value,
          }
        );
        if (!value) {
          this.perWeeks = 1;
          this.repeatingDays = [];
        }
      },
    },
    isTimeLimited: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.isTimeLimited;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "isTimeLimited",
          value,
        });
        if (!value) {
          this.valid = { start: null, end: null };
        }
      },
    },
    perWeeks: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.repetition
          .perWeeks;
      },
      set(value) {
        this.$store.commit(
          "rewardingScheme/UPDATE_REWARDING_SCHEME_REPETITION",
          {
            field: "perWeeks",
            value,
          }
        );
      },
    },
    repeatingDays: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.repetition
          .repeatingDays;
      },
      set(value) {
        this.$store.commit(
          "rewardingScheme/UPDATE_REWARDING_SCHEME_REPETITION",
          {
            field: "repeatingDays",
            value,
          }
        );
      },
    },
  },
  methods: {
    validate() {
      this.$refs.rewardSchemaForm.validate().then(async (success) => {
        if (success) {
          if (this.edit) this.save();
          else await this.create();
        }
      });
    },
    resetState() {
      this.$store.commit("rewardingScheme/RESET_STATE");
    },
    async create() {
      try {
        this.$store.commit("rewardingScheme/SET_REQUESTING", true);
        const res = await this.$store.dispatch(
          "rewardingScheme/createRewardingScheme"
        );
        this.$store.commit(
          "rewardingSchemeTable/ADD_REWARDING_SCHEME",
          res.data.data
        );
        this.$bvModal.hide("rewarding-scheme");
        this.$bvToast.toast("Reward created", {
          title: "Rewarding Scheme",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error accrued while creating the reward", {
          title: "Rewarding Scheme",
          variant: "danger",
        });
      } finally {
        this.$store.commit("rewardingScheme/SET_REQUESTING", false);
      }
    },
    async save() {
      try {
        this.$store.commit("rewardingScheme/SET_REQUESTING", true);
        const res = await this.$store.dispatch(
          "rewardingScheme/saveRewardingScheme"
        );
        this.$store.commit(
          "rewardingSchemeTable/UPDATE_REWARDING_SCHEME_DATA",
          res.data.data
        );
        this.$bvModal.hide("rewardingScheme");
        this.$bvToast.toast("Reward updated", {
          title: "Rewarding scheme",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error accrued while updating the reward", {
          title: "Rewarding scheme",
          variant: "danger",
        });
      } finally {
        this.$store.commit("rewardingScheme/SET_REQUESTING", false);
      }
    },
  },
};
</script>

<style scoped></style>
