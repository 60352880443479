<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingRewardingSchemes"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="72vh"
    responsive="true"
    class="m-table__wrapper"
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    :tbody-tr-class="rowClass"
  >
    <template #cell(name)="{ item, value }">
      <i
        v-if="!item.isActive"
        class="fas fa-exclamation-triangle text-yellow-color"
        v-b-tooltip="
          'Reward is disabled! Edit the reward to set it back to active.'
        "
      ></i>
      {{ value }}
    </template>
    <template #cell(tokenAmount)="{ value }">
      <span class="mr-3">
        <img
          :src="require('@/assets/images/butterlfy-icon.png')"
          class="token-icon"
          v-b-tooltip.hover="'Number of butterflies'"
        />
        x{{ value }}
      </span>
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex justify-content-end">
        <b-button
          variant="action"
          v-b-tooltip.hover="'Edit reward'"
          @click="edit(item)"
        >
          <i class="fad fa-pencil-alt"></i>
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "RewardingSchemeTable",
  data() {
    return {
      fields: [
        {
          key: "name",
          sortable: true,
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "store.name",
          label: "store",
          sortable: true,
          tdClass: "store",
          thClass: "store",
        },
        {
          key: "tokenAmount",
          label: "Points needed",
          sortable: true,
          tdClass: "tokens",
          thClass: "tokens",
        },
        {
          key: "text",
          label: "Gratis products",
          sortable: false,
          tdClass: "text",
          thClass: "text",
        },
        {
          key: "actions",
          label: "",
          sortable: true,
          tdClass: "actions",
          thClass: "actions",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.rewardingSchemeTable.rewardingSchemes;
    },
    fetchingRewardingSchemes() {
      return this.$store.state.rewardingSchemeTable.requesting;
    },
  },
  async created() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      await this.$store.dispatch("rewardingSchemeTable/getRewardingSchemes");
    },
    edit(item) {
      this.$store.commit("rewardingScheme/SET_REWARDING_SCHEME", item);
      this.$bvModal.show("rewarding-scheme");
    },
    rowClass(item, type) {
      let className = "";
      if (item && type === "row") {
        if (!item.isActive) className = "inactive";
      }
      return "m-table__body-tr " + className;
    },
  },
};
</script>

<style scoped>
/deep/.actions {
  width: 5%;
}
/deep/.tokens {
  width: 15%;
}
/deep/.text {
  width: 40%;
}
/deep/.name {
  width: 20%;
}
/deep/.store {
  width: 10%;
}
</style>
