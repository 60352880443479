<template>
  <div class="col-12 px-0 my-4">
    <div class="d-flex justify-content-between mb-3">
      <b-button
        variant="primary"
        size="sm"
        :disabled="requesting"
        @click="$bvModal.show('rewardingScheme-products')"
        >Select products</b-button
      >
      <b-input-group class="col-4 px-0">
        <b-input-group-append>
          <div class="m-input__icon left">
            <i class="far fa-search"></i>
          </div>
        </b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search products"
          class="border-left-0 pl-0 py-2 mr-2"
          debounce="500"
        ></b-form-input>
      </b-input-group>
    </div>

    <b-table
      ref="table"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="5"
      :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
      :filter="searchQuery"
      empty-filtered-text="No results"
      empty-text="No products selected yet"
      show-empty
      selectable
      select-mode="multi"
      no-select-on-click
      borderless
      sticky-header="72vh"
      responsive="true"
      class="m-table__wrapper"
      table-class="m-table"
      thead-class="m-table__header"
      thead-tr-class="m-table__header-tr"
      tbody-class="m-table__body"
      tbody-tr-class="m-table__body-tr"
    >
      <template #cell(remove)="{ item }">
        <b-button
          variant="action"
          @click="removeProducts(item.id)"
          v-b-tooltip="'Remove selected product'"
          ><i class="fas fa-times"></i
        ></b-button>
      </template>
      <template #cell(quantity)="{ item }">
        <b-form-input
          v-model="item.quantity"
          type="number"
          size="sm"
          :disabled="requesting"
        ></b-form-input>
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-center">
      <b-pagination
        v-model="currentPage"
        per-page="5"
        :total-rows="items.length"
        pills
        class=""
      />
      <validation-provider
        tag="div"
        name="repeating"
        class="d-flex justify-content-end mb-3 col-6 px-0"
        v-slot="{ errors }"
      >
        <b-form-checkbox v-model="freeShipping"
          >Products have free shipping</b-form-checkbox
        >
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <add-product />
  </div>
</template>

<script>
import AddProduct from "../../../rewarding-scheme/modals/rewarding-scheme-types/modal/AddProduct";
export default {
  name: "RewardProducts",
  components: { AddProduct },
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: "name",
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "sku",
          tdClass: "sku",
          thClass: "sku",
        },
        {
          key: "stock",
          tdClass: "stock",
          thClass: "stock",
        },
        {
          key: "source",
          tdClass: "source",
          thClass: "source",
        },
        {
          key: "quantity",
          label: "Quantity",
          tdClass: "tokens",
          thClass: "tokens",
        },
        {
          key: "remove",
          label: "",
          tdClass: "name",
          thClass: "name",
        },
      ],
      searchQuery: "",
    };
  },
  computed: {
    requesting() {
      return this.$store.state.rewardingScheme.requesting;
    },
    items() {
      return this.$store.state.rewardingScheme.rewardingScheme.products;
    },
    freeShipping: {
      get() {
        return this.$store.state.rewardingScheme.rewardingScheme.freeShipping;
      },
      set(value) {
        this.$store.commit("rewardingScheme/UPDATE_REWARDING_SCHEME_FIELDS", {
          field: "freeShipping",
          value,
        });
      },
    },
  },
  watch: {
    items: {
      deep: true,
      handler(products) {
        console.log("Items watch triggered");
        this.$store.commit(
          "rewardingScheme/UPDATE_REWARDING_SCHEME_PRODUCTS",
          products
        );
      },
    },
  },
  methods: {
    removeProducts(selectedId) {
      const index = this.items.findIndex(({ id }) => id === selectedId);
      this.$store.commit("rewardingScheme/REMOVE_PRODUCT", index);
    },
  },
};
</script>

<style scoped></style>
